import React, { FC, FocusEvent, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Divider, Input, Typography } from 'antd';

import styles from './Services.module.scss';

import { LoadingStatus } from '../../../../store/types';
import { IService } from '../../../../store/schedule';
import ServicesTable from './ServicesTable';
import ServiceType from '../ServiceType';
import useScheduleServices from '../../../../hooks/useScheduleServices';
import { getOffsetTop } from '../../../../utils/getOffsetTop';

interface ServicesProps {
  date: string;
  addService: (service: IService) => void;
  removeService: (service: IService) => void;
  value: IService[];
}

const StyledDivider = () => {
  return <Divider style={{ borderTop: '2px solid rgba(10, 10, 255, 0.26)' }} />;
};

const Services: FC<ServicesProps> = ({
  date,
  value,
  addService,
  removeService,
}) => {
  const [serviceTypeUUID, setServiceTypeUUID] = useState('');
  const { services, loading } = useScheduleServices(serviceTypeUUID, date);

  const [serviceName, setServiceName] = useState<string>('');

  const handleAddService = (service: IService) => ({
    onClick: () => {
      if (!value.some((s) => s.serviceUUID === service.serviceUUID)) {
        addService(service);
      } else {
        removeService(service);
      }
    },
  });

  const handleRemoveService = (service: IService) => ({
    onClick: () => {
      removeService(service);
    },
  });

  const handleServiceNameInputFocus = (e: FocusEvent) => {
    const isMobileScreen = window.screen.width < 415;
    if (!isMobileScreen) {
      return;
    }

    setTimeout(() => {
      const scrollHeight = getOffsetTop(e.target);
      const modal = document.querySelector('.ant-modal-wrap');
      modal?.scroll(0, scrollHeight);
    }, 1000);
  };

  const filteredServices = services.filter(
    (s) =>
      s.article.toLowerCase().includes(serviceName.toLowerCase()) ||
      s.name.toLowerCase().includes(serviceName.toLowerCase())
  );

  const chosenServicesData = serviceName ? filteredServices : services;

  return (
    <div>
      <ServiceType
        value={serviceTypeUUID}
        setServiceType={setServiceTypeUUID}
      />
      <StyledDivider />

      <Input
        style={{ width: '95%' }}
        placeholder="Введіть артикул, або назву послуги"
        value={serviceName}
        onChange={(e) => setServiceName(e.target.value)}
        onFocus={handleServiceNameInputFocus}
      />
      <StyledDivider />
      <ServicesTable
        title="Оберіть послуги зі списку"
        placeholder="Введіть значення в полі пошуку"
        services={chosenServicesData}
        loading={loading === LoadingStatus.LOADING}
        onRow={handleAddService}
        rowClassName={(rowService) =>
          value.some(
            (service) => service.serviceUUID === rowService.serviceUUID
          )
            ? styles.chosenRow
            : ''
        }
      />
      <StyledDivider />
      {!!value.length && (
        <>
          <ServicesTable
            className={styles.selectedServicesTable}
            title="Обрані послуги"
            placeholder="Додайте послуги"
            services={value}
            loading={loading === LoadingStatus.LOADING}
            onRow={handleRemoveService}
          />
          <div style={{ textAlign: 'left', padding: 10 }}>
            <Typography>
              Сумарна вартість обстежень:{' '}
              <b>{value.reduce((s, c) => s + c.price, 0)} грн</b>
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Services);
